import styled from 'styled-components';

export const colors = {
  active: '#FFFFFF',
  logo: '#f9ed34',
  linkBG: '#2C2C46',
  linkBGRGB: 'rgba(44,44,70,.5)',
  background: '#171729',
  borderedLink: '#6D6DAF',
  lightBg: '#f2f4f8',
};

export const PageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
`;

export const TreeLogo = styled.svg`
  height: 80px;
  width: auto;

  path {
    fill: ${colors.logo};
  }
`;

export const TreeLinksWrapper = styled.div`
  margin: 50px 0;
`;

export const TreeLink = styled.a`
  display: block;
  width: 300px;
  padding: 14px 0;
  border-radius: 4px;
  color: ${colors.active};
  background-color: ${colors.linkBG};
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.active};
    color: ${colors.linkBG};
  }
`;

export const TreeSocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  max-width: 300px;
  padding: 6px 0;
  margin-bottom: 30px;
  background-color: ${colors.linkBGRGB};

  a {
    text-decoration: none;
    color: ${colors.active};
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${colors.active};
      color: ${colors.linkBG};
    }
  }
`;

export const TreeAdminLink = styled.a`
  background-color: transparent;
  border: 1px solid ${colors.borderedLink};
  color: ${colors.borderedLink};
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 18px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${colors.borderedLink};
    color: ${colors.active};
  }
`;
