import React from 'react';
import { capitalizeFirstLetter } from '../utils/fontStyling';
import { links } from './App';
import { PageWrapper } from './styled/App';

const Socials = ({ ...props }) => {
  return (
    <PageWrapper>
      <h3>Connect with DevTips!</h3>
      <div className="socials">
        <ul className={'dt-socials'}>
          {links.map((item, index) => {
            if (item.type === 'social') {
              return (
                <li>
                  <a
                    href={item.link}
                    rel={'noreferrer'}
                    target={'_blank'}
                  >
                    {capitalizeFirstLetter(item.text)}
                  </a>
                </li>
              );
            } else { return null }
          })}
        </ul>
      </div>
    </PageWrapper>
  );
};

export default Socials;
