import React, { useState, useEffect } from 'react';
import {
  TreeLink,
  TreeSocialMediaLinks,
  TreeAdminLink,
  colors,
  TreeWrapper,
  TreeLinksWrapper,
  PageWrapper,
} from './styled/Tree';
import LOGO from './images/devtips_logo';
import http from '../http/http-common';

export const links = [
  {
    text: 'DevTips Show on YouTube',
    link: 'https://www.youtube.com/devtipsfordesigners',
    type: 'spotlight',
  },
  {
    text: 'DevTips Merchandise',
    link: 'https://www.devtipsstore.com',
    type: 'spotlight',
  },
  {
    text: "Transitioning DocumentFragment to React.Fragment",
    link:
      'https://devtipsshow.medium.com/transitioning-documentfragment-to-react-fragment-4817a1c77acf?sk=7bd2fdb07edd74b19b2374cae968ae69',
    type: 'spotlight',
  },
  {
    text: 'instagram',
    link: 'https://www.instagram.com/officialdevtipsshow',
    type: 'social',
  },
  {
    text: 'facebook',
    link: 'https://www.facebook.com/DevTipsShow',
    type: 'social',
  },
  {
    text: 'twitter',
    link: 'https://www.twitter.com/DevTipsShow',
    type: 'social',
  },
  {
    text: 'youtube',
    link: 'https://www.youtube.com/DevTipsForDesigners',
    type: 'social',
  },
  {
    text: 'discord',
    link: 'https://www.keeponhacking.com',
    type: 'social',
  },
];

const App = ({ ...props }) => {  
  document.body.style.backgroundColor = colors.background;

  return (
    <PageWrapper>
      <TreeWrapper>
        <div style={{ textAlign: 'center' }}>
          <LOGO />
          <TreeLinksWrapper>
            {links.map(
              (item, index) =>
                item.type === 'spotlight' && (
                  <TreeLink
                    key={index}
                    href={item.link}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {item.text}
                  </TreeLink>
                ),
            )}
          </TreeLinksWrapper>
          <TreeSocialMediaLinks>
            {links.map(
              (item, index) =>
                item.type === 'social' && (
                  <a
                    key={index}
                    href={item.link}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <i className={`fab fa-${item.text}`} />
                    <span style={{ display: 'none' }}>
                      {item.text}
                    </span>
                  </a>
                ),
            )}
          </TreeSocialMediaLinks>
        </div>
      </TreeWrapper>
    </PageWrapper>
  );
}

export default App;
