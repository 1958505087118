import React from 'react'
import { TreeLogo } from '../styled/Tree'

const LOGO = () => (
  <TreeLogo id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.859 82.377">
    <g id="Group_6" data-name="Group 6">
      <g id="Group_2" data-name="Group 2" transform="translate(18.84 7.945)">
        <path id="Path_9" data-name="Path 9" d="M836.222,490.841h9.217c9.958,0,16.374,4.688,16.374,15.292s-6.416,15.56-15.961,15.56h-9.629Zm8.77,25.236c5.53,0,9.267-2.565,9.267-9.944s-3.737-9.676-9.267-9.676h-1.4v19.62Z" transform="translate(-836.222 -490.841)" />
        <path id="Path_10" data-name="Path 10" d="M877.506,490.841h20.43v5.85H884.878v6.2h11.116v5.85H884.878v7.108h13.558v5.85h-20.93Z" transform="translate(-845.739 -490.841)" />
        <path id="Path_11" data-name="Path 11" d="M907.666,490.841h7.8l3.931,14.377c.97,3.374,1.624,6.487,2.612,9.892h.2c.988-3.4,1.683-6.518,2.612-9.892l3.847-14.377h7.536l-9.834,30.852h-8.855Z" transform="translate(-852.691 -490.841)" />
      </g>
      <g id="Group_3" data-name="Group 3" transform="translate(18.84 43.188)">
        <path id="Path_12" data-name="Path 12" d="M843.945,543.211h-7.723v-5.833h21.792v5.833H850.29V568.14h-6.344Z" transform="translate(-836.222 -536.812)" />
        <path id="Path_13" data-name="Path 13" d="M870.234,537.378h6.344V568.14h-6.344Z" transform="translate(-844.062 -536.812)" />
        <path id="Path_14" data-name="Path 14" d="M887.076,537.378h9.641c6.174,0,11.154,2.421,11.154,9.9,0,7.23-5.036,10.429-10.982,10.429H893.42V568.14h-6.344Zm9.438,14.8c3.489,0,5.139-1.705,5.139-4.908,0-3.219-1.879-4.374-5.311-4.374H893.42v9.281Z" transform="translate(-847.945 -536.812)" />
        <path id="Path_15" data-name="Path 15" d="M917.508,564.089l3.644-4.772a10.7,10.7,0,0,0,6.893,3.214c2.649,0,3.944-1.143,3.944-2.969,0-1.937-1.6-2.558-4.047-3.663l-3.646-1.7a8.9,8.9,0,0,1-5.663-8.385c0-5.1,4.154-9.168,10-9.168a12.4,12.4,0,0,1,9.027,4.031l-3.2,4.383a8.685,8.685,0,0,0-5.824-2.409c-2.188,0-3.58,1-3.58,2.758,0,1.887,1.836,2.572,4.285,3.641l3.576,1.587c3.427,1.519,5.567,4.02,5.567,8.368,0,5.108-3.885,9.534-10.572,9.534A14.729,14.729,0,0,1,917.508,564.089Z" transform="translate(-854.96 -536.642)" />
      </g>
      <g id="Group_4" data-name="Group 4">
        <path id="Path_16" data-name="Path 16" d="M818.667,550.119c0-6.948.784-12,.784-18.424,0-3.741-1.634-7.432-7.713-7.5v-4.978c6.08-.07,7.713-3.761,7.713-7.381,0-6.544-.784-11.6-.784-18.544,0-9.931,3.361-12.774,10.478-12.774h4.011v4.5h-2.342c-4.848,0-6.119,2.532-6.119,8.724,0,5.793.472,10.84.472,17.364,0,6.21-1.458,9.255-5.361,10.4v.383c3.9,1.15,5.361,4.316,5.361,10.405,0,6.524-.472,11.572-.472,17.364,0,6.192,1.27,8.724,6.119,8.724h2.342v4.5h-4.011C822.028,562.893,818.667,560.05,818.667,550.119Z" transform="translate(-811.738 -480.516)" />
      </g>
      <g id="Group_5" data-name="Group 5" transform="translate(98.441)">
        <path id="Path_17" data-name="Path 17" d="M943.68,562.893h-4.01v-4.5h2.342c4.849,0,6.119-2.532,6.119-8.724,0-5.792-.471-10.84-.471-17.364,0-6.089,1.457-9.255,5.36-10.405v-.383c-3.9-1.15-5.36-4.194-5.36-10.4,0-6.524.471-11.571.471-17.364,0-6.192-1.269-8.724-6.119-8.724H939.67v-4.5h4.01c7.118,0,10.478,2.843,10.478,12.774,0,6.948-.784,12-.784,18.544,0,3.62,1.633,7.311,7.713,7.381v4.978c-6.08.07-7.713,3.761-7.713,7.5,0,6.423.784,11.476.784,18.424C954.159,560.05,950.8,562.893,943.68,562.893Z" transform="translate(-939.67 -480.516)" />
      </g>
    </g>
    <path id="Path_549" data-name="Path 549" d="M1.488-8.967h.629V-11.9h.994v-.529H.494v.529h.994Zm2.195,0h.571v-1.576a9.812,9.812,0,0,0-.1-1.121H4.18l.275.836.55,1.544h.413l.561-1.544.275-.836h.021a9.812,9.812,0,0,0-.1,1.121v1.576h.582v-3.459h-.74l-.592,1.692c-.074.222-.127.423-.19.677H5.217c-.063-.254-.127-.455-.2-.677l-.592-1.692h-.74Z" transform="translate(113.097 12.426)" />
  </TreeLogo>
)

export default LOGO