import styled from 'styled-components';
import { colors } from './Tree';

export const PageWrapper = styled.div`
  margin: 20px auto;
  position: relative;
  background-color: ${colors.lightBg};
  width: calc(100% - 100px);
  border-radius: 4px;
  color: ${colors.linkBG};
  display: block;
  padding: 16px;
`;
