import React, { useState } from 'react';
import http from '../http/http-common';

const NewLink = ({ ...props }) => {
  window.document.title = 'Create A New Link';
  const [formData, setFormData] = useState({
    text: '',
    link: '',
    type: '',
  });

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    http
      .post(`/links/new`, formData, {
        'Content-Type': 'application/json',
      })
      .then((res) => {
        console.log(res);
        return (window.location.href = 'http://localhost:3000');
      })
      .catch((err) => console.error(err));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={formData.text}
        placeholder="Text"
        name="text"
        onChange={handleChange}
      />
      <input
        type="text"
        value={formData.link}
        placeholder="Link"
        name="link"
        onChange={handleChange}
      />
      <input
        type="text"
        value={formData.type}
        placeholder="Type"
        name="type"
        onChange={handleChange}
      />
      <button type="submit" onClick={handleSubmit}>
        Submit
      </button>
    </form>
  );
};

export default NewLink;
