import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './styles/main.scss';
import App from './components/App';
import Socials from './components/Socials';
import Blogs from './components/Blogs';
import Resources from './components/Resources';
import NewLink from './components/NewLink';

export default function AppRouter({ ...props }) {
  return (
    <Router>
      <Switch>
        <Route exact path={'/'} component={App} />
        <Route exact path={'/new-link'} component={NewLink} />
        <Route exact path={'/socials'} component={Socials} />
        <Route exact path={'/blogs'} component={Blogs} />
        <Route exact path={'/resources'} component={Resources} />
      </Switch>
    </Router>
  );
}
